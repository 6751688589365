import http from "./http";
export default (cashierId) => ({
  async index(filters) {
    return http
      .index("cashiers", filters)
      .then((json) => ({ cashiers: json }))
      .catch(async (e) => ({ cashiers: null, error: await e }));
  },
  async get() {
    return http
      .get("cashiers", cashierId)
      .then((json) => ({ cashier: json }))
      .catch(async (e) => ({ cashier: null, error: await e }));
  },
  async create(data) {
    return http
      .post("cashiers", data)
      .then((json) => ({ cashier: json }))
      .catch(async (e) => ({ cashier: null, error: await e }));
  },
  async update(data) {
    return http
      .put("cashiers", cashierId, data)
      .then((json) => ({ cashier: json }))
      .catch(async (e) => ({ cashier: null, error: await e }));
  },
  async delete() {
    return http
      .delete("cashiers", cashierId)
      .then((json) => ({ cashier: json }))
      .catch(async (e) => ({ cashier: null, error: await e }));
  },
});
