<template>
  <el-card v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ isNew ? 'Abertura de caixa' : 'Fechamento de caixa' }}</h4>
      </el-row>
    </template>
    <el-descriptions border columns="2">
      <el-descriptions-item>
        <template #label> Data: </template>
        {{ dateFormatter.format(new Date()) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Responsável: </template>
        {{ $store.state?.user?.name }}
      </el-descriptions-item>
    </el-descriptions>
    <form v-if="isNew">
      <el-row type="flex" justify="start">
        <h5>MOVIMENTAÇÕES INICIAIS</h5>
      </el-row>
      <base-input v-model="cashier.money_start" v-on:keydown.enter="save" type="money" label="Caixa inicial:"></base-input>
    </form>
    <form v-else>
      <el-row type="flex" justify="start">
        <h5>MOVIMENTAÇÃO DO DIA</h5>
      </el-row>
      <base-input v-model="cashier.coin" type="money" label="Moeda:"></base-input>
      <base-input v-model="cashier.money" type="money" label="Dinheiro:"></base-input>
      <base-input v-model="cashier.credit" type="money" label="Cartão de Crédito:"></base-input>
      <base-input v-model="cashier.debit" type="money" label="Cartão de Débito:"></base-input>
      <base-input v-model="cashier.checks" type="money" label="Cheque:"></base-input>
      <base-input v-model="cashier.pix" type="money" label="PIX:"></base-input>
      <base-input v-model="cashier.delivery" type="money" label="Entregas:"></base-input>
      <base-input v-model="cashier.debts" type="money" label="Convênio:"></base-input>
      <base-input v-model="cashier.ticket" type="money" label="Ticket:"></base-input>
      <base-input v-model="cashier.expenses" type="money" label="Despesas:"></base-input>
      <el-row type="flex" justify="start">
        <h5>REFORÇO</h5>
      </el-row>
      <base-input v-model="cashier.start" type="money" label="Reforço:"></base-input>
      <el-row type="flex" justify="start">
        <h5>DIVERSOS</h5>
      </el-row>
      <el-row :gutter="4" type="flex">
        <el-col :md="12" :sm="24">
          <base-input v-model="discount" type="money" label="Desconto:"></base-input>
        </el-col>
        <el-col :md="12" :sm="24">
          <base-input v-model="clients" label="Clientes:"></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="4" type="flex">
        <el-col :md="12" :sm="24">
          <base-input v-model="cashier.canceled" type="money" label="Cancelamento de Venda:"></base-input>
        </el-col>
        <el-col :md="12" :sm="24">
          <base-input v-model="cashier.canceled_reasons" label="Motivo:"></base-input>
        </el-col>
      </el-row>
      <base-input v-model="cashier.withdrawal" type="money" label="Sangria:"></base-input>
      <el-row type="flex" justify="start">
        <h5>RESULTADO</h5>
      </el-row>
      <base-input v-model="total" type="money" disabled label="Total em Caixa:"></base-input>
    </form>
    <el-divider class="negative-margin"></el-divider>
    <el-row type="flex" justify="end">
      <el-button type="text" :loading="isLoadingSave" class="button" @click="save">Salvar</el-button>
    </el-row>
  </el-card>
</template>
<script>
import { ElNotification } from "element-plus";
import CashierService from "../services/cashier";
import BaseInput from "../components/BaseInput.vue";
export default {
  name: "CashierClose",
  components: {
    BaseInput,
  },
  computed: {
    isNew() {
      return !("uid" in this.cashier);
    },
  },
  watch:{
    discount(){
      this.updateTotal();
    }
  },
  mounted() {
    this.$watch(
      function () {
        return Object.values(this.cashier);
      },
      function () {
        this.updateTotal();
      },
      { deep: true }
    );

    this.loadOpenCashier();
  },
  data() {
    return {
      isLoadingSave: false,
      isLoading: false,
      discount: 0,
      total: 0,
      clients: null,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
        timeStyle: "short",
      }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      cashier: {},
    };
  },
  methods: {
    totalSells() {
      const total = Number(this.total) || 0;
      const withdrawal = Number(this.cashier?.withdrawal) || 0;
      //const discount = Number(this.discount) || 0;
      const start = Number(this.cashier.start) || 0;
      return Math.max(total + withdrawal - start, 0);
    },
    async save() {
      this.isLoadingSave = true;

      const saveMethod = this.isNew ? CashierService().create : CashierService(this.cashier.uid).update;

      const { error } = await saveMethod({
        ...this.cashier,
        status: "created",
        total_sells: this.totalSells(),
        discount: this.discount,
        discount_clients: this.clients,
        total_cashier: this.total,
        type: 'factory',
        reference: this.cashier.reference || new Date(),
      });

      if (error)
        return ElNotification.error({
          title: "Não foi possível cadastrar o fechamento de caixa",
          message: error.message,
        });

      ElNotification.success({
        title: this.isNew
          ? "Fechamento de caixa cadastrado com sucesso."
          : "Fechamento de caixa atualizado com sucesso.",
      });
      
      this.$router.replace('/fabrica/caixa/listagem')
    },
    async loadOpenCashier(){
      const { cashiers } = await CashierService().index({type: 'factory', status: 'created'});

      if(cashiers?.length){
        this.cashier = cashiers[0]
        this.discount = this.cashier.discount;
      }
    },
    updateTotal() {
      let t = 0;
      t -= Number(this.discount) || 0;
      t += Number(this.cashier?.money_start) || 0;
      t += Number(this.cashier?.delivery) || 0;
      t -= Number(this.cashier?.debts) || 0;
      t -= Number(this.cashier?.expenses) || 0;
      //t -= Number(this.cashier?.start) || 0;
      //t += Number(this.cashier?.canceled) || 0;
      t += Number(this.cashier?.coin) || 0;
      t += Number(this.cashier?.money) || 0;
      t += Number(this.cashier?.credit) || 0;
      t += Number(this.cashier?.ticket) || 0;
      t += Number(this.cashier?.debit) || 0;
      t += Number(this.cashier?.pix) || 0;
      this.total = Math.max(t, 0);
    },
  },
};
</script>
<style scoped></style>